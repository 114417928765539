<template>
  <div class="sub-menu">
    <div class="nav-bar">
      <div class="menu-links">
        <div
          class="menu-link"
          v-if="
            getPermissionInterface('Gestion des appels à facturation régie')
          "
        >
          <router-link class="menu-item" to="/regies/gestion-facture">
            Gestion des appels à facturation régie
          </router-link>
        </div>
        <div
          class="menu-link"
          v-if="getPermissionInterface('Suivi des régies')"
        >
          <router-link class="menu-item" to="/regies/suivi-regie">
            Suivi des régies
          </router-link>
        </div>
        <div
          class="menu-link"
          v-if="getPermissionInterface('Récapitulatif régie par zone')"
        >
          <router-link class="menu-item" to="/regies/recap-regie">
            Récapitulatif régie par zone
          </router-link>
        </div>

        <div class="menu-link" v-if="getPermissionInterface('Coût des régies')">
          <router-link class="menu-item" to="/regies/validation-cout">
            Coût des régies
          </router-link>
        </div>
        <div
          class="menu-link"
          v-if="getPermissionInterface('Liste des documents')"
        >
          <router-link class="menu-item" to="/regies/all-document-regie">
            Liste des documents
          </router-link>
        </div>
      </div>
    </div>
    <div class="body">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['getUserData', 'getPermissionInterface'])
  }
};
</script>

<style scoped lang="scss"></style>
